import React, { useState, useContext, useEffect } from "react";
import "./OTJContainer.css";
import {
  Avatar,
  Box,
  Card,
  Typography,
  Skeleton,
  CardContent,
  Stack,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../../context/appContext";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import InfoIcon from "@mui/icons-material/Info";
import LearnerInfoModal from "../LearnerInfoModal/LearnerInfoModal";
import { getHours } from "../../utils/functions";
import LearnerGraphDrawer from "../LearnerGraphDrawer/LearnerGraphDrawer";
import axios from "axios";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },
  divStylee: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  boxStylePie: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
  boxStyleLine: {
    marginTop: 20,
    padding: 40,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    width: "100%",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 4,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
    padding: "20px",
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardSubText: {
    fontSize: 12,
    color: "white",
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  typoText1: {
    fontFamily: "Poppins",
    fontSize: 9,
    fontWeight: 600,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  buttonn: {
    background: "#939393",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
    marginTop: 20,
  },
}));

const OTJContainer = (props) => {
  const classes = useStyles();
  // data, setData, handleToggle, updateMessage, getUserData
  const { data } = useContext(AppContext);
  const [showLearnerInfoModal, setShowLearnerInfoModal] = useState(false);
  const [showLearnerGraphDrawer, setShowLearnerGraphDrawer] = useState(false);
  const [response, setResponse] = useState([]);
  const [chartData, setChartData] = useState([]);
  // console.log("chartData::", response);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { userData } = props;

  const differencehrs = getHours(
    userData?.progress?.currentTarget - userData?.progress?.cureentProgress
  ).hours;
  const differenceMin = getHours(
    userData?.progress?.currentTarget - userData?.progress?.cureentProgress
  ).minutes;

  // const SubmittedHrs = getHours(userData.progress.cureentProgress).hours;
  // const SubmittedMin = getHours(userData.progress.cureentProgress).hours;

  // console.log("differencehrs", differencehrs, differenceMin);
  // Fetch chart data when learner ID is available
  useEffect(() => {
    if (userData?._id) {
      const fetchChartData = async () => {
        try {
          setLoading(true);
          const res = await axios.get(`/learner/chart-data/${userData._id}`, {
            config: { handlerEnabled: true },
          });
          const data = res.data.data;
          setResponse({
            ...res.data,
            currentMonth: { ...res.data.currentMonth }, // Ensure new reference
          });
          setChartData(data?.weeklyData || []);
          setLoading(false);
        } catch (e) {
          setError(e.message);
          setLoading(false);
        }
      };

      fetchChartData();
    }
  }, [userData]);
  // Get the current month and year
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentYear = currentDate.getFullYear();
  const currentMonthYear = `${currentMonth} ${currentYear}`;
  const submittedByMonth = response?.monthlyData?.find(
    (e) => e?.month === currentMonthYear
  );
  if (submittedByMonth) {
    // console.log(
    //   `Submitted hours for ${currentMonthYear}: ${submittedByMonth.Submitted}`
    // );
  } else {
    console.log(`No data found for ${currentMonthYear}`);
  }
  // get current week submiited hours

  const todaysDate = new Date(); // Get the current date as a Date object

  // (either 'newcurrentSeconds' or 'targetSeconds')
  function getCurrentWeekData(field) {
    if (!userData?.monthlyHourMinutes) {
      console.log("No weekly data available.");
      return 0;
    }

    for (let week of userData?.monthlyHourMinutes) {
      const startDate = new Date(week.startDate.split("/").reverse().join("-"));
      const endDate = new Date(week.endDate.split("/").reverse().join("-"));

      // Check if today's date is within the week range
      if (todaysDate >= startDate && todaysDate <= endDate) {
        return week[field];
      }
    }

    return 0; // Fallback in case today's date is not in any of the weeks
  }

  // Usage for submitted hours (newcurrentSeconds)
  const callCurrentSubmittedHours = getCurrentWeekData("newcurrentSeconds");

  // Usage for targeted hours (targetSeconds)
  const callCurrentTargetHours = getCurrentWeekData("targetSeconds");

  const sumOfAllSubmittedSeconds = userData?.monthlyHourMinutes?.reduce(
    (total, week) => {
      return total + week.newcurrentSeconds;
    },
    0
  );
  const sumOfAllTargetSeconds = userData?.progress?.currentTarget;
  const differenceBetweenAllSubmittedAndTarget = Math.abs(
    sumOfAllTargetSeconds - sumOfAllSubmittedSeconds
  );
  console.log("Sum of all submitted seconds:", sumOfAllSubmittedSeconds);
  console.log("Sum of all target seconds:", sumOfAllTargetSeconds);
  console.log(
    "Difference (always positive):",
    differenceBetweenAllSubmittedAndTarget
  );
  return (
    <>
      <Box className={classes.cardStyle}>
        {data && userData && (
          <Card>
            <CardContent className="card-content">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" component="h3" gutterBottom>
                  Off-The-Job Hours
                </Typography>
                <IconButton
                  onClick={() =>
                    setShowLearnerGraphDrawer(!showLearnerGraphDrawer)
                  }
                  color="secondary"
                  aria-label="reset"
                >
                  <BarChartIcon sx={{ cursor: "pointer" }} title="Close" />
                </IconButton>
              </Stack>

              <Stack
                sx={{
                  height: 40,
                  // borderLeft: "4px solid red",
                  // background: "#E5E5E5",
                  width: "100%",
                  marginTop: 2,
                  marginBottom: 1,
                  borderRadius: 1,
                  backgroundColor: "rgba(21, 101, 192, 0.08)",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Typography variant="h5" component="h3" textAlign="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end", // Align items to the bottom
                      justifyContent: "center",
                    }}
                  >
                    {sumOfAllSubmittedSeconds >= sumOfAllTargetSeconds && (
                      <CheckBoxOutlinedIcon
                        sx={{ color: "green", marginRight: "8px" }} // Add margin for spacing
                        title="On target"
                      />
                    )}
                    {sumOfAllSubmittedSeconds < sumOfAllTargetSeconds && (
                      <WarningAmberOutlinedIcon
                        sx={{ color: "red", marginRight: "8px" }} // Add margin for spacing
                        title="On target"
                      />
                    )}
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      {/* {getHours(userData.progress.cureentTarget).hours} -
                      {getHours(userData.progress.cureentProgress).hours}
                      <span style={{ fontSize: "12px", marginRight: "4px" }}>
                        hr{" . "}
                      </span>
                      {getHours(userData.progress.cureentTarget).hours} -
                      {getHours(userData.progress.cureentProgress).minutes}
                      <span style={{ fontSize: "12px" }}>min</span> */}
                      {getHours(differenceBetweenAllSubmittedAndTarget).hours}
                      <span style={{ fontSize: "12px", marginRight: "4px" }}>
                        hr{" . "}
                      </span>
                      {getHours(differenceBetweenAllSubmittedAndTarget).minutes}
                      <span style={{ fontSize: "12px" }}>min</span>
                    </div>
                  </div>
                </Typography>
                {/* <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "100%" }} 
                /> */}
                <Tooltip title="The Difference between the completed hours and targeted hours.">
                  <Typography sx={{ p: 1 }}>
                    {sumOfAllSubmittedSeconds < sumOfAllTargetSeconds
                      ? "Below Target"
                      : "On Target"}
                  </Typography>
                </Tooltip>
              </Stack>

              <Stack
                sx={{
                  height: 90,
                  // borderLeft: "4px solid red",
                  // background: "#E5E5E5",
                  backgroundColor: "rgba(21, 101, 192, 0.08)",

                  width: "100%",
                  marginTop: 2,
                  marginBottom: 1,
                  borderRadius: 1,
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {loading && null}
                  {error && <p>Error: {error}</p>}
                  {response && (
                    <>
                      {/*
                      <Typography component="p" variant="p">
                        {response.currentMonth?.hours} hrs
                      </Typography> */}{" "}
                      <Tooltip title="Total submitted off-the-job hours for the current month.">
                        <Typography component="h3" variant="p">
                          {response.currentMonth?.month}
                        </Typography>
                      </Tooltip>
                      <Typography component="p" variant="body1">
                        {submittedByMonth?.Submitted
                          ? `${
                              submittedByMonth?.Submitted?.split(".")[0]
                            }hr . ${
                              submittedByMonth?.Submitted?.split(".")[1]
                            }min`
                          : " 0hr . 00min"}
                      </Typography>
                      <Typography component="p" variant="p">
                        Submitted
                      </Typography>
                    </>
                  )}
                </Stack>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "100%" }}
                />
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Tooltip title="Estimated total off-the-job hours the learner will complete after the practical period ends.">
                    <Typography component="h3" variant="p">
                      Forecast
                    </Typography>
                  </Tooltip>
                  {/* <Typography component="h3" variant="p">
                    {userData?.monthlyHourMinutes.length} weeks
                  </Typography> */}
                  <Typography component="p" variant="p">
                    {getHours(userData.totalSeconds).hours}

                    <span style={{ fontSize: "12px" }}>hr{" . "}</span>
                    {getHours(userData.totalSeconds).minutes}
                    <span style={{ fontSize: "12px" }}>min</span>
                  </Typography>
                  <Typography component="p" variant="p">
                    Targeted
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Box>

      <LearnerGraphDrawer
        showTraining={false}
        close={() => setShowLearnerGraphDrawer(false)}
        open={showLearnerGraphDrawer}
        userData={data}
      />
    </>
  );
};

export default OTJContainer;
