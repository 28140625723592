import {
  useState,
  useEffect,
  useContext,
  useCallback,
  Fragment,
  useLayoutEffect,
  useRef,
} from "react";
import "./Learner.css";
import ApprentiseshipStatusModal from "../../components/ApprentiseshipStatusModal/ApprentiseshipStatusModal";
import TimeAgo from "react-timeago";
import moment from "moment";
import { makeStyles } from "@mui/styles";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Menu,
  List,
  ListItem,
  Tooltip,
  Skeleton,
  Alert,
  Stack,
} from "@mui/material";
// import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrackChangesSharpIcon from "@mui/icons-material/TrackChangesSharp";
import GetAppIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import StopIcon from "@mui/icons-material/Stop";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@mui/x-date-pickers";
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import axios from "axios";
import enLocale from "date-fns/locale/en-GB";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { debounce, cloneDeep, isEmpty } from "lodash";
import { getDateFromUkString, getHours } from "../../utils/functions";

import TimeInput from "../../components/TimeInput/TimeInput";
import ImageDropzone from "../../components/ImageDropzone/ImageDropzone";
import RefreshButton from "../../components/RefreshButton/Refresh";
import ProfileContainer from "../../components/ProfileContainer/ProfileContainer";
import InputAdornment from "@mui/material/InputAdornment";
import UpdateDiaryModal from "../../components/UpdateDiaryModal/UpdateDiaryModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import AttachmentPreview from "../../components/AttachmentPreview/AttachmentPreview";
import { RestrictFeature } from "../../components/RestrictFeature/RestrictFeature";
import NameAvatar from "../../components/NameAvatar";
import HelpAndSupport from "../../components/HelpAndSupport/HelpAndSupport";
import MenuListComposition from "./dropDown";
import OTJContainer from "../../components/OTJContainer/OTJContainer";
import { useNavigate } from "react-router-dom";
import LearnerTrainingModal from "../../components/LearnerTrainingModal/LearnerTrainingModal";
// import { DatePicker } from "@mui/x-date-pickers";
// import Skeleton from "@mui/lab/Skeleton";

const plannedOTJMessage = {
  On: "On target",
  Below: "To be complete",
  Over: "Over target",
};

const useStyles = makeStyles(() => ({
  noDataImage: { display: "flex", justifyContent: "center" },
  divStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divStylee: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },
  accordianBox: {
    paddingTop: 15,
    borderRadius: 4,
    paddingBottom: 15,
    marginTop: 20,
    marginBottom: 20,
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15) !important",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  filterBoxStyle: {
    marginTop: 20,
    padding: 20,
    borderRadius: 4,
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.10) !important",
    // border: "1px solid lightGray",
    display: "flex",
    justifyContent: "flex-end",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 4,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  profileCard: {
    background: "#5335CA !important",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15) !important",
    borderRadius: 4,
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardText1: {
    fontSize: 12,
    color: "white",
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  text2: {
    marginTop: "30px",
    // marginLeft: "25px",
  },
  text1Sub: {
    fontSize: "1.1rem",
  },
  buttonn: {
    color: "white !important",
    background: "#939393 !important",
    marginRight: 10,
    "&:hover": {
      background: "#888888 !important",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  heading: {
    fontSize: 15,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: 10,
    alignSelf: "center",
  },
  accordianGrid: {
    marginBottom: 20,
  },
  accordianText: {
    fontSize: "12px",
  },
  accDiv: {
    display: "flex",
    // gap: "1rem",
    overflowY: "auto",
    // margin: "0 -.5rem",
  },
  boldFont: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 900,
  },
  buttons: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "end",
  },
  formControl: {
    width: "100%",
    maxWidth: "12.5rem",
  },
  accordianSummaryContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  root: {
    maxWidth: "100%",
  },
  buttonCont: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    background: "#5335CA !important",
    color: "white",
    "&:hover": {
      background: "#5335CA !important",
    },
  },
  mr: {
    marginRight: "2px",
  },
  ml: {
    marginLeft: "2px",
  },
  full: {
    width: "100%",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA !important",
    cursor: "pointer",
  },
  Downloadicon: {
    height: 14,
    widht: 14,
    color: "#5335CA !important",
    cursor: "pointer",
  },
  accordianImg: {
    maxHeight: 150,
    maxWidth: 150,
  },
  altImg: {
    height: 130,
    width: 130,
  },
  altImgCont: {
    display: "flex",
    fontSize: "12px",
    flexDirection: "column",
    width: 150,
    alignItems: "center",
    textAlign: "center",
  },
  imgPreviewCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: ".5rem",
  },
  imgCont: {
    display: "flex",
    // gap: ".5rem",
    justifyContent: "center",
  },
  skeleton: {
    backgroundColor: "#fff !important",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15) !important",
    borderRadius: 4,
    marginTop: 20,
  },
}));

export const LearnerPage = () => {
  moment.locale("en-GB");
  const targetSectionRef = useRef(null);
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  // console.log("dataxxxx", data);
  const classes = useStyles();
  const [userData, setUserData] = useState();
  const [entries, setEntries] = useState([]);
  const [preview, setPreview] = useState({
    show: false,
    file: "",
    type: "",
  });
  const [apprentiseshipStatusModal, setApprentiseshipStatusModal] =
    useState(false);

  // to get id of the learner
  let { id } = useParams();
  const isDesktop = window.innerWidth > 899 ? true : false;
  // console.log("entries", entries);
  // console.log("userData", userData);

  // initial state variable
  let initialState = {
    diaryState: {
      title: "",
      description: "",
      requestAcknowledgement: false,
      submitToAllLearners: false,
      attachments: [],
    },
    activityState: {
      type: "Not OTJ Learning Activity",
      startDate: new Date(),
      endDate: new Date(),
      hours: "",
      minutes: "",
    },
    standardsState: {
      duties: "",
      knowledge: "",
      skills: "",
      behaviour: "",
      others: "",
    },
    errorState: {
      titleError: false,
      descriptionError: false,
      hoursError: false,
      minutesError: false,
    },
  };
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [entryToDelete, setEntryToDelete] = useState();
  const [diaryModal, setDiaryModal] = useState(false);
  const [singleEntry, setSingleEntry] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [showLearnerTrainingModal, setShowLearnerTrainingModal] =
    useState(false);
  // ungli
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activities, setActivities] = useState([]); // To track existing activities
  const [filter, setFilter] = useState({
    type: [],
    weekName: "",
    query: "",
    // startDate: new Date(),
    // endDate: new Date(),
    startDate: "",
    endDate: "",
  });

  let helpAndSupportMessage = `Subscription plan - `;
  const [diary, setDiary] = useState(initialState.diaryState);
  const [learningActivity, setLearningActivity] = useState(
    initialState.activityState
  );
  const [occupationalStandards, setOccupationalStandards] = useState(
    initialState.standardsState
  );
  const [error, setError] = useState(initialState.errorState);

  //occupationa Standards Change Handler
  const occupationalStandardsChangeHandler = (e) => {
    const value = e.target.value;
    setOccupationalStandards({
      ...occupationalStandards,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    getData(filter);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterStartDate = (newValue) => {
    let obj = {
      ...filter,
      startDate: newValue,
    };
    setFilter(obj);
  };
  const handleFilterEndDate = (newValue) => {
    let obj = {
      ...filter,
      endDate: newValue,
    };
    setFilter(obj);
  };

  const handleFilterChange = async (e) => {
    let obj = {
      ...filter,
      [e.target.name]: e.target.value,
    };
    setFilter(obj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, obj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const entryFilterHandler = async () => {
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filter, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const clearHandler = async () => {
    let filterObj = {
      type: [],
      weekName: "",
      query: "",
      startDate: null,
      endDate: null,
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };
  // Download Dropdown buttons
  const [download, setDownload] = useState(null);

  const handleClick = (event) => {
    setDownload(event.currentTarget);
  };

  const handleClose = () => {
    setDownload(null);
  };
  // To Apply Search Filter
  const entryFilterSearchHitApi = async (e) => {
    let newFilters = cloneDeep(filter);
    newFilters.query = e;
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, newFilters, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  // To Apply Search With Debounce
  const handler = useCallback(debounce(entryFilterSearchHitApi, 1000), [
    filter,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Set Search Filter Value
  const entryFilterSearchHandler = async (e) => {
    let filterObj = { ...filter, [e.target.name]: e.target.value };
    setFilter(filterObj);
    handler(e.target.value);
  };

  //to remove filter from chips
  const removeFilterType = async (value) => {
    let filterObj;
    if (value) {
      // let index = filter.type.findIndex((tag) => tag == item);
      // if (index != -1) {

      // }
      let arr = filter.type.filter((item) => item !== value);

      filterObj = {
        ...filter,
        type: arr,
      };
      setFilter(filterObj);
    } else {
      filterObj = {
        ...filter,
        type: [],
      };
      setFilter(filterObj);
    }

    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const removeFilterMonth = async () => {
    let filterObj = {
      ...filter,
      weekName: "",
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const removeFilterStartDate = async () => {
    let filterObj = {
      ...filter,
      startDate: null,
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const removeFilterendDate = async () => {
    let filterObj = {
      ...filter,
      endDate: null,
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const deleteEntry = async () => {
    try {
      handleToggle(true);
      await axios
        .delete(`learner/entry/${entryToDelete}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          getData(filter);
          handleToggle(false);
          updateMessage(response.data.message);
          setConfirmationModal(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        setConfirmationModal(false);
        handleToggle(false);

        if (e?.response?.status === 400) {
          console.error("Error message:", e.response.data.message);
          setApprentiseshipStatusModal(true);
        }
      }
    }
  };

  // const handleStartDateChange = (date) => {
  //   setLearningActivity({ ...learningActivity, startDate: date });
  // };

  // const handleEndDateChange = (date) => {
  //   setLearningActivity({ ...learningActivity, endDate: date });
  // };
  const isOverlap = (newStartDate, newEndDate, activities) => {
    return activities.some((activity) => {
      const activityStart = new Date(activity.startDate);
      const activityEnd = new Date(activity.endDate);

      return newStartDate < activityEnd && newEndDate > activityStart;
    });
  };

  const handleStartDateChange = (date) => {
    const newStartDate = date;
    const newEndDate = learningActivity.endDate;

    if (newEndDate && isOverlap(newStartDate, newEndDate, activities)) {
      setError({
        ...error,
        dateError:
          "The selected date range overlaps with an existing activity.",
      });
    } else {
      setLearningActivity({ ...learningActivity, startDate: newStartDate });
    }
  };

  const handleEndDateChange = (date) => {
    const newEndDate = date;
    const newStartDate = learningActivity.startDate;

    if (newStartDate && isOverlap(newStartDate, newEndDate, activities)) {
      setError({
        ...error,
        dateError:
          "The selected date range overlaps with an existing activity.",
      });
    } else {
      setLearningActivity({ ...learningActivity, endDate: newEndDate });
    }
  };

  const handleSubmit = () => {
    if (
      isOverlap(
        learningActivity.startDate,
        learningActivity.endDate,
        activities
      )
    ) {
      setError({
        ...error,
        dateError:
          "The selected date range overlaps with an existing activity.",
      });
    } else {
      // Save the new activity
      setActivities([...activities, learningActivity]);
      // Clear form or perform other submission logic
    }
  };

  const learningActivityChangeHandler = (e) => {
    setLearningActivity({
      ...learningActivity,
      type: e.target.value,
    });
  };

  const diaryChangeHandler = (e) => {
    const value = e.target.value;
    setError({ ...error, [e.target.name + "Error"]: value ? false : true });
    if (
      e.target.name === "requestAcknowledgement" ||
      e.target.name === "submitToAllLearners"
    ) {
      setDiary({
        ...diary,
        [e.target.name]: e.target.checked,
      });
    } else {
      setDiary({
        ...diary,
        [e.target.name]: value,
      });
    }
  };

  const validateForm = () => {
    let titleError = false;
    let descriptionError = false;
    let hoursError = false;
    let minutesError = false;

    if (diary.title === "") {
      titleError = "Please enter the title";
    }
    if (diary.description === "") {
      descriptionError = "Please enter the description";
    }
    if (learningActivity.type !== "Not OTJ Learning Activity") {
      if (learningActivity.hours === "") {
        hoursError = "Please enter the hours";
      }
      if (learningActivity.minutes === "") {
        minutesError = "Please enter the minutes";
      }
    }
    if (titleError || descriptionError || hoursError || minutesError) {
      setError({
        ...error,
        titleError,
        descriptionError,
        hoursError,
        minutesError,
      });
      return false;
    }
    return true;
  };

  //submit diary entry form
  const submitHandler = async () => {
    const isValid = validateForm();
    if (isValid) {
      // Check for date range overlap
      if (
        isOverlap(
          learningActivity.startDate,
          learningActivity.endDate,
          activities
        )
      ) {
        setError({
          ...error,
          dateError:
            "The selected date range overlaps with an existing activity.",
        });
        return; // Exit the function to prevent form submission
      }

      let { attachments } = diary;
      let formData = new FormData();
      for (let i = 0; i < attachments.length; i++) {
        formData.append("files", attachments[i]);
      }
      let data = {
        title: diary.title,
        description: diary.description,
        acknowledgement: diary.requestAcknowledgement,
        submitToAllLearners: diary.submitToAllLearners,
        standards: occupationalStandards,
        activity: learningActivity,
        createdForId: id,
      };
      formData.append("data", JSON.stringify(data));
      try {
        handleToggle(true);
        await axios
          .post("learner/entry", formData, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            diary.attachments.forEach((file) =>
              URL.revokeObjectURL(file.preview)
            );
            setDiary(initialState.diaryState);
            setLearningActivity(initialState.activityState);
            setOccupationalStandards(initialState.standardsState);
            setError(initialState.errorState);
            handleToggle(false);
            updateMessage(response.data.message);
            let filter = {
              type: [],
              weekName: "",
              startDate: "",
              endDate: "",
            };
            setFilter(filter);
            getData(filter);
            getData();
            // window.location.reload();
          });
      } catch (e) {
        if (e.response && e.response.data) {
          handleToggle(false);

          if (e?.response?.status === 400) {
            console.error("Error message:", e.response.data.message);
            setErrorMsg(e.response.data.message);
            setApprentiseshipStatusModal(true);
          }
        }
      }
    } else {
      updateMessage("Please Fill All The Required Fields!!");
    }
  };
  // useEffect(() => {
  //   submitHandler();
  // }, []);
  //reset diary entry form
  const resetHandler = () => {
    setDiary(initialState.diaryState);
    setLearningActivity(initialState.activityState);
    setOccupationalStandards(initialState.standardsState);
    setError(initialState.errorState);
  };
  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //download Report
  const downloadHandler = async () => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-OTJ-report/${data._id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${data.name}'s report`);
          link.href = fileURL;

          // Auto click the link
          link.click();

          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);

        if (e?.response?.status === 400) {
          setApprentiseshipStatusModal(true);
        }
      }
    }
  };

  const downloadEvidenceReport = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-evidence-report/${id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });

          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          let cleanFileName =
            `${data.name.replace(
              /[^a-zA-Z0-9 ]/g,
              ""
            )} OTJ Training Evidence`.replace(/ /g, "_") + ".pdf";
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");

          document.documentElement.append(link);

          link.setAttribute("download", cleanFileName);

          link.href = fileURL;

          // Auto click the link
          link.click();
          handleToggle(false);

          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const downloadSingleDiaryHandler = async (e, id, title, name) => {
    // console.log("name", name)
    e.stopPropagation();
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-single-diary/${id}/${name}`, {
          config: { handlerEnabled: true },
          // body:  name ,
          responseType: "blob",
        })
        .then((response) => {
          // console.log("res", response);
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          // console.log("file", file);
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          // console.log("fileURL", fileURL);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${title} Diary`);
          link.href = fileURL;

          // Auto click the link
          link.click();
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);

        if (e?.response?.status === 400) {
          setApprentiseshipStatusModal(true);
        }
      }
    }
  };

  const learningActivityFilter = [
    "Classroom Training",
    "Training at Work",
    "Learning Support",
    "Lectures",
    "Online Learning",
    "Shadowing",
    "Mentoring",
    "Writing Assignments",
    "Theory Learning",
    "Manufacturer Training",
    "Role Playing",
    "Simulation Exercises",
    "Industry Visits",
    "Participation in Competitions",
    "Revision",
    "Other OTJ Learning Activity",
    "Not OTJ Learning Activity",
  ];

  const getHoursMinutesFormated = (seconds) => {
    let hoursMinutes = getHours(seconds);
    return hoursMinutes.hours + ":" + hoursMinutes.minutes;
  };

  const getTextColor = (status) => {
    if (status === "On") {
      return { color: "#7CBD1A" };
    } else if (status === "Below") {
      return { color: "#BD1A41" };
    } else {
      return { color: "#0000FF" };
    }
  };

  const openDiaryEditModal = (e, entry) => {
    e.stopPropagation();
    setDiaryModal(true);
    setSingleEntry(entry);
  };

  const closeDiaryEditModal = (e) => {
    setDiaryModal(false);
  };

  const getData = async (filter) => {
    try {
      await axios
        .get(`learner/profile`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setUserData(response.data.data);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
    try {
      await axios
        .post(`learner/entries/${id}`, filter, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const acceptAcknowledgement = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`/learner/accept-ack/${id}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          // props.refreshData();
          getData(filter);
          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        console.error(e);
      }
    }
  };

  const openConfirmationModal = (e, entryId) => {
    e.stopPropagation();
    setEntryToDelete(entryId);
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const filePreviewError = () => {
    setPreview({
      show: false,
    });
    updateMessage("Something went wrong while displaying file.");
  };

  var isMobileDevice = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobileDevice = true;
  }

  useEffect(() => {
    getData();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDiariesDownload = async (id, name) => {
    // e.stopPropagation();
    try {
      handleToggle(true);
      await axios
        .post(
          `learner/download-filter-entries/${id}`,
          { ...filter, Name: name },
          {
            config: { handlerEnabled: true },
            responseType: "blob",
          }
        )
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${data.name} Diaries`);
          link.href = fileURL;

          // Auto click the link
          link.click();
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);

        if (e?.response?.status === 400) {
          setApprentiseshipStatusModal(true);
        }
      }
    }
  };

  let convertedStartDate = new Date(filter.startDate);
  const formattedStartDate = `${convertedStartDate.getDate()}-${
    convertedStartDate.getMonth() + 1
  }-${convertedStartDate.getFullYear()}`;

  let convertedEndDate = new Date(filter.endDate);
  const formattedEndDate = `${convertedEndDate.getDate()}-${
    convertedEndDate.getMonth() + 1
  }-${convertedEndDate.getFullYear()}`;

  // console.log("Ldata", data)
  const diaryRecords = () => (
    <Container maxWidth="xl">
      {/* <FirstTimeAccess /> */}
      <Fragment>
        {data ? (
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.boxStyle}>
                <div className="headerContainer">
                  <div className={classes.headingContainer}>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learning Diary Records
                    </Typography>
                    <span
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0 -.5rem",
                        maxWidth: "20rem",
                      }}
                    >
                      {filter.type &&
                        filter.type.length > 0 &&
                        filter.type.map((item) => (
                          <Chip
                            label={item}
                            onDelete={() => removeFilterType(item)}
                            size="small"
                            style={{ margin: ".5rem" }}
                          />
                        ))}
                      {filter.weekName && (
                        <Chip
                          label={filter.weekName}
                          onDelete={removeFilterMonth}
                          size="small"
                          style={{ margin: ".5rem" }}
                        />
                      )}
                      {filter.startDate && (
                        <Chip
                          label={`Start Date: ${formattedStartDate.toString()}`}
                          onVisibilityChange
                          onDelete={removeFilterStartDate}
                          size="small"
                          style={{ margin: ".5rem" }}
                        />
                      )}
                      {filter.endDate && (
                        <Chip
                          label={`End Date: ${formattedEndDate.toString()}`}
                          onDelete={removeFilterendDate}
                          size="small"
                          style={{ margin: ".5rem" }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="filterContainer">
                    <TextField
                      label="Search"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {filter.query !== "" && (
                              <CancelIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let customEvent = {
                                    target: {
                                      name: "query",
                                      value: "",
                                    },
                                  };
                                  entryFilterSearchHandler(customEvent);
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      name="query"
                      value={filter.query}
                      onChange={entryFilterSearchHandler}
                    />
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Tooltip arrow title="Filter" placement="right">
                        <IconButton>
                          <FilterListIcon
                            onClick={handleMenuOpen}
                            // className={classes.icons}
                            sx={{ width: "1rem" }}
                          />
                        </IconButton>
                      </Tooltip>
                      {entries && entries.length > 0 && (
                        // <Tooltip arrow title="Download" placement="right">
                        //   <IconButton
                        //     onClick={() =>
                        //       handleDiariesDownload(data._id, data?.name)
                        //     }
                        //   >
                        //     <DownloadIcon className={classes.Downloadicon} />
                        //   </IconButton>
                        // </Tooltip>
                        // <>
                        //   <Tooltip arrow title="Download" placement="right">
                        //     <IconButton onClick={handleClick}>
                        //       <DownloadIcon />
                        //     </IconButton>
                        //   </Tooltip>
                        //   <Menu
                        //     sx={{ p: 3 }}
                        //     // id="simple-menu"
                        //     anchorEl={download}
                        //     keepMounted
                        //     open={Boolean(download)}
                        //     onClose={handleClose}
                        //   >
                        //     <Button
                        //       sx={{ mb: "10px" }}
                        //       fullWidth
                        //       onClick={downloadHandler}
                        //       startIcon={<GetAppIcon size="small" />}
                        //       // className={classes.button}
                        //     >
                        //       LEARNER REPORT
                        //     </Button>

                        //     <RestrictFeature available={["standard"]}>
                        //       <Button
                        //         sx={{ mb: "10px" }}
                        //         fullWidth
                        //         onClick={() => downloadEvidenceReport(data._id)}
                        //         startIcon={<GetAppIcon size="small" />}
                        //         className={classes.button}
                        //       >
                        //         OTJ EVIDENCE
                        //       </Button>
                        //     </RestrictFeature>
                        //     <Button
                        //       sx={{ mb: "10px" }}
                        //       fullWidth
                        //       onClick={() =>
                        //         handleDiariesDownload(data._id, data?.name)
                        //       }
                        //       startIcon={<GetAppIcon size="small" />}
                        //       // className={classes.button}
                        //     >
                        //       LEARNER DIARIES
                        //     </Button>
                        //   </Menu>
                        // </>
                        <MenuListComposition
                          downloadHandler={downloadHandler}
                          downloadEvidenceReport={downloadEvidenceReport}
                          downloadSingleDiaryHandler={
                            downloadSingleDiaryHandler
                          }
                          handleDiariesDownload={handleDiariesDownload}
                          handleClose={handleClose}
                          data={data}
                        />
                      )}
                    </Box>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <List
                        component="nav"
                        className={classes.root}
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItem className={classes.buttonCont}>
                          <Button
                            className={`${classes.mr} ${classes.button}`}
                            onClick={clearHandler}
                          >
                            Clear
                          </Button>
                          <Button
                            className={`${classes.ml} ${classes.button}`}
                            onClick={entryFilterHandler}
                          >
                            Filter
                          </Button>
                        </ListItem>
                        <ListItem>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                              Filter Learner Activity
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Filter Learner Activity"
                              fullWidth
                              multiple
                              value={filter.type}
                              onChange={handleFilterChange}
                              name="type"
                            >
                              {learningActivityFilter.map((activity, index) => {
                                return (
                                  <MenuItem key={index} value={activity}>
                                    {activity}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </ListItem>
                        <ListItem>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                              Filter By Week
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Filter By Week"
                              fullWidth
                              name="weekName"
                              value={filter.weekName}
                              onChange={handleFilterChange}
                            >
                              {userData &&
                                userData.monthlyHourMinutes.map(
                                  (month, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={month.weekName}
                                      >
                                        {`${month.weekName}  (${month.targetHours}:${month.targetMinutes})`}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </FormControl>
                        </ListItem>
                        <ListItem>
                          <Typography sx={{ fontWeight: "600" }}>
                            Learning Diary Records
                          </Typography>
                        </ListItem>
                        <FormControl>
                          <ListItem>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enLocale}
                            >
                              <DatePicker
                                name="startDate"
                                label="Start Date"
                                fullWidth
                                value={filter.startDate}
                                onChange={(newValue) =>
                                  handleFilterStartDate(newValue)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={false}
                                  />
                                )}
                                inputFormat="dd/MM/yyyy"
                              />
                            </LocalizationProvider>
                          </ListItem>
                          <ListItem>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={enLocale}
                            >
                              <DatePicker
                                name="endDate"
                                label="End Date"
                                fullWidth
                                value={filter.endDate}
                                onChange={(newValue) =>
                                  handleFilterEndDate(newValue)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    error={false}
                                  />
                                )}
                                inputFormat="dd/MM/yyyy"
                              />
                            </LocalizationProvider>
                          </ListItem>
                        </FormControl>
                      </List>
                    </Menu>
                  </div>
                </div>

                {entries && entries.length > 0 ? (
                  entries.map((entry, index) => {
                    return (
                      <Accordion key={index} className={classes.accordianBox}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div
                            className={`accordianSummaryContainer ${classes.accordianSummaryContainer}`}
                          >
                            <div
                              className="ldr-acc-head"
                              style={{ display: "flex" }}
                            >
                              {/* <Avatar
                                alt={entry?.createdById?.name}
                                src={data?.imageUrl}
                                sx={{ width: 50, height: 50 }}
                                style={{
                                  marginRight: ".5rem",
                                  marginBottom: ".5rem",
                                }}
                              /> */}

                              <div style={{ alignSelf: "center" }}>
                                <Typography style={{ fontSize: 15 }}>
                                  {entry.title}
                                </Typography>
                                <div
                                  className="accHeadTime"
                                  style={{ display: "flex" }}
                                >
                                  <Typography
                                    className={classes.secondaryHeading}
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <TimeAgo
                                      date={entry.createdAt}
                                      // title={moment(
                                      //   entry.activity.startDate
                                      // ).format("DD-MMM-YY HH:mm")}
                                      title={moment(entry.createdAt).format(
                                        "DD-MMM-YY HH:mm"
                                      )}
                                    />
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: 10,
                                      alignSelf: "center",
                                      color: "#6B3AFF",
                                    }}
                                  >
                                    {entry.isEdited ? (
                                      <Tooltip
                                        title={
                                          <TimeAgo date={entry.updatedAt} />
                                        }
                                        arrow
                                        placement="right"
                                      >
                                        <span>Edited</span>
                                      </Tooltip>
                                    ) : null}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            {data && (
                              <div className="ldr-acc-head-icons">
                                <IconButton
                                  onClick={(e) =>
                                    downloadSingleDiaryHandler(
                                      e,
                                      entry._id,
                                      entry.title,
                                      data?.name
                                    )
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>

                                <IconButton
                                  onClick={(e) => openDiaryEditModal(e, entry)}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  onClick={(e) =>
                                    openConfirmationModal(e, entry._id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid container className={classes.cardStyle}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                Title
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.title}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Description
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Activity OFF-The-Job
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.activity.type}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Start date & time
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {moment(entry.activity.startDate).format(
                                  "LLLL"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                End date & time
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {moment(entry.activity.endDate).format(
                                  // "DD-MMM-YY"
                                  "LLLL"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Duration
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.activity.hours ? (
                                  <span>
                                    {entry.activity.hours}
                                    {entry.activity.hours > 1
                                      ? " Hours "
                                      : " Hour "}
                                  </span>
                                ) : (
                                  "0 Hour"
                                )}
                                {entry.activity.minutes ? (
                                  <span>
                                    {entry.activity.minutes}
                                    {entry.activity.minutes > 1
                                      ? " Minutes"
                                      : " Minute"}
                                  </span>
                                ) : (
                                  " 0 Minute"
                                )}
                              </Typography>
                            </Grid>
                            {/* </>
                          )} */}
                            {/* <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Occupational standards
                              </Typography>
                            </Grid> */}
                            {/* <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.boldFont}>
                                Duties:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.duties === ""
                                  ? "N/A"
                                  : entry.standards.duties}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Knowledge:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.knowledge === ""
                                  ? "N/A"
                                  : entry.standards.knowledge}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Skills:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.skills === ""
                                  ? "N/A"
                                  : entry.standards.skills}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Behaviour:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.behaviour === ""
                                  ? "N/A"
                                  : entry.standards.behaviour}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Others:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.others === ""
                                  ? "N/A"
                                  : entry.standards.others}
                              </Typography>
                            </Grid> */}
                            {/* <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                File Attachments
                              </Typography>
                            </Grid> */}
                            {/* <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <div className={`accDiv ${classes.accDiv}`}>
                                {entry.attachments.length > 0 ? (
                                  entry.attachments.map((file, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className={classes.imgPreviewCont}
                                      >
                                        {file.type.includes("image") ? (
                                          <img
                                            src={
                                              isMobileDevice
                                                ? file.mobile
                                                : file.desktop
                                            }
                                            alt="Img here"
                                            className={classes.accordianImg}
                                          />
                                        ) : (
                                          <div className={classes.altImgCont}>
                                            <img
                                              src="https://res.cloudinary.com/my-7startraining/image/upload/v1662025613/586281-200_q1jdbq.png"
                                              alt={file.name}
                                              className={classes.altImg}
                                            />
                                            <span
                                              style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "150px",
                                              }}
                                            >
                                              {file.name}
                                            </span>
                                          </div>
                                        )}

                                        <span className={classes.imgCont}>
                                          <a href={file.preview}>
                                            <IconButton
                                              style={{ marginRight: ".5rem" }}
                                            >
                                              <DownloadIcon />
                                            </IconButton>
                                          </a>
                                          {file.type !=
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                            file.extension != "xlsx" && (
                                              <span>
                                                <IconButton
                                                  onClick={() => {
                                                    setPreview({
                                                      show: true,
                                                      file: file.preview,
                                                      type: file.extension,
                                                    });
                                                  }}
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </span>
                                            )}
                                        </span>
                                      </span>
                                    );
                                  })
                                ) : (
                                  <Typography className={classes.accordianText}>
                                    No Files Added!
                                  </Typography>
                                )}
                              </div>
                            </Grid> */}
                            {data &&
                              (data.role === "learner" ||
                                data.role === "coach") &&
                              entry.acknowledgementStatus && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    className={classes.accordianGrid}
                                  >
                                    <Typography
                                      className={classes.accordianText}
                                    >
                                      Acknowledgement
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={8}
                                    className={classes.accordianGrid}
                                  >
                                    <Typography
                                      className={classes.accordianText}
                                    >
                                      {data?._id === entry?.createdById?._id &&
                                      entry.createdById._id ? (
                                        `Acknowledgement ${entry.acknowledgementStatus}`
                                      ) : (
                                        <span>
                                          {entry.acknowledgementStatus ===
                                          "accepted" ? (
                                            "Acknowledgement accepted"
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                acceptAcknowledgement(entry._id)
                                              }
                                              variant="contained"
                                              className={classes.button}
                                            >
                                              <Typography variant="h8">
                                                Accept Acknowledgement
                                              </Typography>
                                            </Button>
                                          )}
                                        </span>
                                      )}
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className={classes.noDataImage}>
                    <img src="/no-data-found.png" alt="" />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <div className={classes.skeleton}>
            <Skeleton variant="text" height={50} />
            <Skeleton variant="rect" height={295} />
          </div>
        )}
      </Fragment>

      {!isDesktop && data && data._id === id && (
        <Fragment>
          {userData ? (
            <HelpAndSupport message={helpAndSupportMessage} />
          ) : (
            <div className={classes.skeleton}>
              <Skeleton variant="text" height={45} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="70%" height={45} />
            </div>
          )}
        </Fragment>
      )}
    </Container>
  );

  const precedenceLevel = () => {
    switch (userData && userData.status) {
      case "On":
        return userData.progress.apprenticeshipMessage;
        break;
      case "Off":
        return "Learner Withdrawn";
        break;

      case "Completed":
        return "Learner Has Completed";
        break;
      default:
        // On Break
        return "Learner On Break In Learning";
    }
  };

  useEffect(() => {
    const isFirstLogin = localStorage.getItem("isFirstLogin");
    if (isFirstLogin === "true") {
      setShowWelcomeMessage(true);
      setTimeout(() => {
        localStorage.removeItem("isFirstLogin"); // Remove flag to avoid showing message again
      }, 3000);
    }
  }, []);

  const handleButtonClick1 = () => {
    // Scroll to the target section

    // Close the alert
    setShowWelcomeMessage(false);
  };

  const handleButtonClick = async () => {
    await getData(); // Refresh userData when the modal is opened
    setShowLearnerTrainingModal(true); // Show the modal
    setShowWelcomeMessage(false); // Hide welcome message
  };

  const getBackGroundColor = () => {
    // console.log("userData.status", userData.status);
    // userData.status === "On"
    // ? "rgba(124, 189, 26, 0.1)"
    // : "rgba(189, 26, 65, 0.1)",

    if (userData.targetStatus === "On") {
      return "rgba(124, 189, 26, 0.1)";
    } else if (userData.targetStatus === "Below") {
      return "rgba(189, 124, 26, 0.1)";
    } else {
      return "rgba(189, 26, 65, 0.1)";
    }
  };

  // console.log('Current submitted hours for this week:', callCurrentSubmittedHours);
  const sumOfAllSubmittedSeconds = userData?.monthlyHourMinutes?.reduce(
    (total, week) => {
      return total + week.newcurrentSeconds;
    },
    0
  );
  return (
    <Fragment>
      <Container
        component="main"
        maxWidth="xl"
        className="pd-bottom-40"
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Container maxWidth="xl">
          {showWelcomeMessage && (
            <>
              <Alert severity="warning" sx={{ mt: 2.5, position: "relative" }}>
                {/* Please check your email for the verification link sent to update
                your email address. */}
                Edit your weekly Off-The-Job Hours according to your official
                apprenticeship training plan or learning plan.
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "#5335CA",
                    color: "white",
                    ":hover": {
                      backgroundColor: "#3a2b9f", // Darker shade for hover effect
                    },
                  }}
                >
                  Weekly Off The Job hours
                </Button>
              </Alert>
            </>
          )}

          <LearnerTrainingModal
            showTraining={false} // Assuming this prop is for internal logic of the modal
            close={() => setShowLearnerTrainingModal(false)}
            open={showLearnerTrainingModal}
            userData={userData}
          />
          {/* {!isEmpty(data?.emailToUpdate) && (
            <Alert severity="warning" sx={{ mt: 2.5 }}>
              If you wish, you can edit your weekly Off-The-Job hours according
              to your official apprenticeship training plan or learning plan.
            </Alert>
          )} */}

          {/* <Box>
            {!isDesktop && (
              <ProfileContainer role="learner" userData={userData} />
            )}
          </Box> */}
          <Box>
            {!isDesktop && <OTJContainer role="learner" userData={userData} />}
          </Box>
        </Container>

        <Fragment>
          {data ? (
            <Container maxWidth="xl">
              <Box className={classes.boxStyle}>
                <Grid container>
                  {/* <Grid item xs={12} sm={6} md={8}>
                    <Typography variant="h5" component="h3">
                      Total Performance
                    </Typography>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={3} md={2}>
                    <Button
                      sx={{ mb: "10px" }}
                      fullWidth
                      onClick={downloadHandler}
                      startIcon={<GetAppIcon size="small" />}
                      className={classes.button}
                    >
                      LEARNER REPORT
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} sm={0.1} md={0.1}></Grid>
                  {/* <Grid item xs={12} sm={2.9} md={1.9}>
                    <RestrictFeature available={["standard"]}>
                      <Button
                        sx={{ mb: "10px" }}
                        fullWidth
                        onClick={() => downloadEvidenceReport(data._id)}
                        startIcon={<GetAppIcon size="small" />}
                        className={classes.button}
                      >
                        OTJ EVIDENCE
                      </Button>
                    </RestrictFeature>
                  </Grid> */}
                </Grid>

                {userData && (
                  <Grid container className="learnerPerformanceBox">
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ backgroundColor: "rgba(21, 101, 192, 0.08)" }}
                    >
                      <Fragment>
                        <Tooltip title="Total hours planned for OTJ learning from week 1 to the current week.">
                          <Typography
                            variant="h5"
                            component="h3"
                            sx={{ flexGrow: 1, mb: 1 }}
                          >
                            {
                              userData.monthlyHourMinutes[
                                userData.progress.weekNumber - 1
                              ].weekName
                            }{" "}
                            Target
                          </Typography>
                        </Tooltip>
                        {/* working */}
                        {/* <Typography component="p" variant="h4">
                          {getHours(userData.progress.currentTarget).hours}
                          <span style={{ fontSize: "22px" }}>
                            hr {" . "}
                          </span>{" "}
                          {getHours(userData.progress.currentTarget).minutes}
                          <span style={{ fontSize: "22px" }}>min</span>
                        </Typography> */}
                        <Stack
                          direction="row"
                          sx={{
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <Stack>
                            <Typography component="p" variant="h4">
                              {getHours(userData.progress.currentTarget).hours}
                            </Typography>
                            <Typography component="p" variant="p">
                              Hour
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography component="p" variant="h4">
                              {
                                getHours(userData.progress.currentTarget)
                                  .minutes
                              }
                            </Typography>
                            <Typography component="p" variant="p">
                              Minute
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          <StopIcon
                            style={{ color: "#5335CA" }}
                            className={classes.qIcon}
                          />

                          <span style={{ color: "#5335CA" }}>
                            {
                              userData.monthlyHourMinutes[
                                userData.progress.weekNumber - 1
                              ].startDate
                            }
                            <span style={{ fontSize: "10px" }}>{" - "}</span>
                            {
                              userData.monthlyHourMinutes[
                                userData.progress.weekNumber - 1
                              ].endDate
                            }
                          </span>
                        </Typography> */}
                      </Fragment>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ backgroundColor: "rgba(21, 101, 192, 0.08)" }}
                    >
                      <Fragment>
                        <Tooltip title="Total hours submitted for OTJ learning from week 1 to the current week.">
                          <Typography
                            variant="h5"
                            component="h3"
                            sx={{ flexGrow: 1, mb: 1 }}
                          >
                            {
                              userData.monthlyHourMinutes[
                                userData.progress.weekNumber - 1
                              ].weekName
                            }{" "}
                            Completed{" "}
                          </Typography>
                        </Tooltip>
                        {/* <Typography component="p" variant="h4">
                          {getHours(userData.progress.cureentProgress).hours}
                          <span style={{ fontSize: "22px" }}>hr{" . "}</span>
                          {getHours(userData.progress.cureentProgress).minutes}
                          <span style={{ fontSize: "22px" }}>min</span>
                        </Typography> */}

                        <Stack
                          direction="row"
                          sx={{
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <Stack>
                            <Typography component="p" variant="h4">
                              {getHours(sumOfAllSubmittedSeconds).hours}
                              {/* {entryHours} */}
                            </Typography>
                            <Typography component="p" variant="p">
                              Hour
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography component="p" variant="h4">
                              {getHours(sumOfAllSubmittedSeconds).minutes}
                              {/* {entryMinutes} */}
                            </Typography>
                            <Typography component="p" variant="p">
                              Minute
                            </Typography>
                          </Stack>
                        </Stack>

                        {/* <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          {userData.targetStatus === "On" ? (
                            <TrackChangesSharpIcon
                              style={{ color: "#7CBD1A" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Below" ? (
                            <ArrowDropDownIcon
                              style={{ color: "#BD1A41" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Over" ? (
                            <ArrowDropUpIcon
                              style={{ color: "#0000FF" }}
                              className={classes.qIcon}
                            />
                          ) : null}
                          <span style={getTextColor(userData.targetStatus)}>
                            {
                              getHours(
                                userData.progress.differenceBetweenTarget
                              ).hours
                            }
                            hr{" . "}
                            {
                              getHours(
                                userData.progress.differenceBetweenTarget
                              ).minutes
                            }
                            min{" "}
                          </span>
                          {userData.targetStatus} target
                        </Typography> */}
                      </Fragment>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ backgroundColor: "rgba(21, 101, 192, 0.08)" }}
                    >
                      <Fragment>
                        <Tooltip
                          title="The officially planned OTJ training hours recorded
                          in the learner's Individual Learner Record (ILR)."
                        >
                          <Typography
                            variant="h5"
                            component="h3"
                            sx={{ flexGrow: 1, mb: 1 }}
                          >
                            Planned OTJ Hrs(ILR){" "}
                          </Typography>
                        </Tooltip>
                        {/* <Typography component="p" variant="h4">
                          {getHours(userData.plannedTotalSeconds).hours}
                          <span style={{ fontSize: "22px" }}>
                            hr {" . "}
                          </span>{" "}
                          {getHours(userData.plannedTotalSeconds).minutes}
                          <span style={{ fontSize: "22px" }}>min</span>
                        </Typography> */}
                        <Stack
                          direction="row"
                          sx={{
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <Stack>
                            <Typography component="p" variant="h4">
                              {getHours(userData.plannedTotalSeconds).hours}
                            </Typography>
                            <Typography component="p" variant="p">
                              Hour
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography component="p" variant="h4">
                              {getHours(userData.plannedTotalSeconds).minutes}
                            </Typography>
                            <Typography component="p" variant="p">
                              Minute
                            </Typography>
                          </Stack>
                        </Stack>
                        {/* <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          {userData.targetStatus === "On" ? (
                            <TrackChangesSharpIcon
                              style={{ color: "#7CBD1A" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Below" ? (
                            <ArrowDropDownIcon
                              style={{ color: "#BD1A41" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Over" ? (
                            <ArrowDropUpIcon
                              style={{ color: "#0000FF" }}
                              className={classes.qIcon}
                            />
                          ) : null}
                          <span style={getTextColor(userData.targetStatus)}>
                            {
                              getHours(
                                userData.progress.totalTarget -
                                  userData.progress.cureentProgress
                              ).hours
                            }
                            hr{" . "}
                            {
                              getHours(
                                userData.progress.totalTarget -
                                  userData.progress.cureentProgress
                              ).minutes
                            }
                            min{" "}
                          </span>
                          Total difference
                        </Typography> */}
                      </Fragment>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ backgroundColor: "rgba(21, 101, 192, 0.08)" }}
                    >
                      <Fragment>
                        <Tooltip title=" Total weeks from the start to the end of the learner's apprenticeship practical period.">
                          <Typography
                            variant="h5"
                            component="h3"
                            sx={{ flexGrow: 1, mb: 1 }}
                          >
                            Practical Period Weeks{" "}
                          </Typography>
                        </Tooltip>
                        <Stack>
                          <Typography component="p" variant="h4">
                            {userData.monthlyHourMinutes.length}
                          </Typography>
                          <Typography component="p" variant="p">
                            Weeks
                          </Typography>
                        </Stack>

                        {/* <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          <StopIcon
                            style={{ color: "#5335CA" }}
                            className={classes.qIcon}
                          />

                          <span style={{ color: "#5335CA" }}>
                            {userData.startDate}
                            <span style={{ fontSize: "10px" }}>{" - "}</span>

                            {userData.endDate}
                          </span>
                        </Typography> */}
                      </Fragment>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Container>
          ) : (
            <div className={classes.skeleton}>
              <Skeleton variant="text" height={45} />
              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "0 -10px" }}
              >
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
              </div>
            </div>
          )}
        </Fragment>

        <Grid container className="mainSection">
          <Grid item xs={12} md={8.5}>
            <Fragment>
              {data ? (
                <Fragment>
                  {data && data.role !== "visitor" ? (
                    <Container maxWidth="xl" style={{ height: "100%" }}>
                      <Box style={{ height: "100%" }}>
                        <Grid container style={{ height: "100%" }}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.boxStyle}
                          >
                            <div className={``}>
                              <Typography
                                variant="h5"
                                component="h3"
                                sx={{ flexGrow: 1 }}
                              >
                                Create New Diary
                              </Typography>
                            </div>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              className={classes.form}
                              style={{ paddingBottom: "0" }}
                            >
                              <label className={classes.label}>Title</label>
                              <TextField
                                id="outlined-adornment-amount"
                                name="title"
                                value={diary.title}
                                onChange={diaryChangeHandler}
                                error={error.titleError}
                                helperText={error.titleError}
                              />
                              <label className={classes.label}>
                                Description
                              </label>
                              <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                name="description"
                                onChange={diaryChangeHandler}
                                error={error.descriptionError}
                                value={diary.description}
                                helperText={error.descriptionError}
                              />

                              {/* File Upload Component */}
                              {/* <ImageDropzone
                                diary={diary}
                                setDiary={setDiary}
                              /> */}
                              {data && data.role === "learner" && (
                                <Fragment>
                                  <Accordion className={classes.accordianBox}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        Add learning activity
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.form}
                                      >
                                        <label className={classes.label}>
                                          Learning activity
                                        </label>
                                        <FormControl
                                          className={classes.field}
                                          error={error.chooseActivityError}
                                        >
                                          <InputLabel id="demo-simple-select-error-label">
                                            Choose Activity
                                          </InputLabel>
                                          <Select
                                            disabled={
                                              userData &&
                                              (userData.progress
                                                .apprenticeshipMessage !==
                                                "Practical Period In Progress" ||
                                                userData.status !== "On")
                                            }
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            label="Choose Activity"
                                            value={learningActivity.type}
                                            onChange={
                                              learningActivityChangeHandler
                                            }
                                          >
                                            {learningActivityFilter.map(
                                              (listItem, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={listItem}
                                                  >
                                                    {listItem}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                        <div
                                          className={`addActivityAccordionDates ${classes.divStyle}`}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={enLocale}
                                          >
                                            <DateTimePicker
                                              sx={{
                                                "& .MuiPickersToolbar-penIconButton":
                                                  {
                                                    display: "none",
                                                  },
                                              }}
                                              maxDate={getDateFromUkString(
                                                userData?.endDate
                                              )}
                                              minDate={getDateFromUkString(
                                                userData?.startDate
                                              )}
                                              label="Start Date & Time"
                                              name="startDate"
                                              value={learningActivity.startDate}
                                              onChange={handleStartDateChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  onKeyDown={(e) =>
                                                    e.preventDefault()
                                                  }
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>

                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={enLocale}
                                          >
                                            <DateTimePicker
                                              sx={{
                                                "& .MuiPickersToolbar-penIconButton":
                                                  {
                                                    display: "none",
                                                  },
                                              }}
                                              maxDate={getDateFromUkString(
                                                userData?.endDate
                                              )}
                                              minDate={getDateFromUkString(
                                                userData?.startDate
                                              )}
                                              label="End Date & Time"
                                              name="endDate"
                                              value={learningActivity.endDate}
                                              onChange={handleEndDateChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  onKeyDown={(e) =>
                                                    e.preventDefault()
                                                  }
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>
                                        </div>

                                        <div
                                          className={`addActivityAccordionTime ${classes.cardStyle}`}
                                        >
                                          <TimeInput
                                            setError={setError}
                                            error={error}
                                            learningActivity={learningActivity}
                                            setLearningActivity={
                                              setLearningActivity
                                            }
                                          />
                                        </div>

                                        {error.dateError && (
                                          <p className="error">
                                            {error.dateError}
                                          </p>
                                        )}
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion>
                                  {/* <Accordion className={classes.accordianBox}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        Add occupational standards
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.form}
                                      >
                                        <label className={classes.label}>
                                          Duties
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="duties"
                                          value={occupationalStandards.duties}
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Knowledge
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="knowledge"
                                          value={
                                            occupationalStandards.knowledge
                                          }
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Skills
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="skills"
                                          value={occupationalStandards.skills}
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Behaviour
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="behaviour"
                                          value={
                                            occupationalStandards.behaviour
                                          }
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Others
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="others"
                                          value={occupationalStandards.others}
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion> */}
                                </Fragment>
                              )}
                              <div
                                className={classes.buttons}
                                // style={{ marginTop: "10px" }}
                              >
                                <Button
                                  onClick={submitHandler}
                                  variant="contained"
                                  className={classes.button}
                                  sx={{ mr: 1 }}
                                >
                                  <Typography variant="h5">Submit</Typography>
                                </Button>
                                <Button
                                  onClick={resetHandler}
                                  variant="contained"
                                  className={classes.button}
                                >
                                  <Typography variant="h5">Reset</Typography>
                                </Button>
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                  ) : (
                    diaryRecords()
                  )}
                </Fragment>
              ) : (
                <div className={classes.skeleton} style={{ height: "845px" }}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton
                    variant="rect"
                    style={{ height: "calc(100% - 50px)" }}
                  />
                </div>
              )}
            </Fragment>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Container maxWidth="xl">
              {/* {isDesktop && (
                <ProfileContainer role="learner" userData={userData} />
              )} */}
              {isDesktop && <OTJContainer role="learner" userData={userData} />}
              {userData && data ? (
                <Box className={classes.cardStyle}>
                  <Card>
                    <CardContent className="card-content">
                      <Typography variant="h5" component="h3" gutterBottom>
                        Diaries Progress
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: 3,
                          marginBottom: 3,
                        }}
                      >
                        <div className={classes.divStyle}>
                          <Typography gutterBottom>Diaries Added</Typography>
                          <label>{userData.progress.totalDiaries}</label>
                        </div>
                        <LinearProgress
                          value={userData.progress.totalDiaries}
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: 3,
                          marginBottom: 3,
                        }}
                      >
                        <div className={classes.divStyle}>
                          <Typography gutterBottom>OTJ Diaries</Typography>
                          <label>{userData.progress.totalOtjEntries}</label>
                        </div>
                        <LinearProgress
                          color="success"
                          value={userData.progress.totalOtjEntries}
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: 3,
                          marginBottom: 3,
                        }}
                      >
                        <div className={classes.divStyle}>
                          <Typography gutterBottom>Not OTJ Diaries</Typography>
                          <label>{userData.progress.totalNotOtjEntries}</label>
                        </div>
                        <LinearProgress
                          color="secondary"
                          value={userData.progress.totalNotOtjEntries}
                          variant="determinate"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                </div>
              )}{" "}
              {isDesktop && data && data._id === id && (
                <HelpAndSupport message={helpAndSupportMessage} />
              )}
            </Container>
          </Grid>
        </Grid>

        {preview.show && (
          <AttachmentPreview
            type={preview.type}
            file={preview.file}
            closeModal={() => setPreview({ show: false })}
            onError={filePreviewError}
          />
        )}

        {data ? (
          <Fragment>{data.role !== "visitor" && diaryRecords()}</Fragment>
        ) : (
          <Fragment>
            {data?.role !== "visitor" && (
              <div className={classes.skeleton} style={{ height: "845px" }}>
                <Skeleton variant="text" height={50} />
                <Skeleton
                  variant="rect"
                  style={{ height: "calc(100% - 50px)" }}
                />
              </div>
            )}
          </Fragment>
        )}
      </Container>

      <ConfirmationModal
        open={confirmationModal}
        close={closeConfirmationModal}
        title="Confirmation"
        text="Are you sure you want to delete this entry?"
        ctaTitleText="Delete"
        cta={deleteEntry}
      />

      <ApprentiseshipStatusModal
        ErrorMessage={errorMsg}
        open={apprentiseshipStatusModal}
        close={() => setApprentiseshipStatusModal(false)}
      />

      {diaryModal ? (
        <UpdateDiaryModal
          userData={userData}
          openModal={openDiaryEditModal}
          closeModal={closeDiaryEditModal}
          data={singleEntry}
          refreshData={() => getData(filter)}
          setApprentiseshipStatusModal={setApprentiseshipStatusModal}
          setErrorMsg={setErrorMsg}
        />
      ) : null}
    </Fragment>
  );
};
