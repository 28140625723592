import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Autocomplete,
  Typography,
  Checkbox,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
  Avatar,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import learner, {
  getLearners,
  setAllLearners,
} from "../../redux/slices/learner";
import { useDispatch, useSelector } from "../../redux/store";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/system";
import { Profiler } from "react";
import Alert from "@mui/material/Alert";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import enLocale from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import Iconify from "../Iconify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
}));

const UpdateLearner = ({
  open,
  close,
  tempLearner,
  setTempLearner,
  newLearnerValidations,
}) => {
  const dispatch = useDispatch();
  const [initialLearner, setInitialLearner] = useState({});

  useEffect(() => {
    if (Object.keys(initialLearner).length === 0) {
      setInitialLearner({ ...tempLearner });
    }
  }, [tempLearner, initialLearner]);

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  let { id } = useParams();
  const [profile, setProfile] = useState({
    name: "",
    imageUrl: "",
    newImage: null,
  });

  const { handleToggle, updateMessage, data, getUserData } =
    useContext(AppContext);

  // console.log("data", data);
  const { allFaculties } = useSelector((state) => state.faculty);
  const [filteredFaculties, setFilteredFaculties] = useState({
    coach: [],
    manager: [],
    mentor: [],
    visitor: [],
    trainer: [],
  });
  const [learnerValidations, setLearnerValidations] = useState(
    newLearnerValidations
  );
  const [hasChanged, setHasChanged] = useState(false);

  const updateLearnerChangeHandler = (e) => {
    if (e && e.target && e.target.name) {
      let { name, value, checked } = e.target;

      if (
        name === "trainingProviderName" ||
        name === "employerName" ||
        name === "apprenticeshipStandard"
      ) {
        const isFieldEmpty = value.trim() === "";
        setTempLearner({
          ...tempLearner,
          [name]: value,
        });

        setLearnerValidations({
          ...learnerValidations,
          [name]: isFieldEmpty,
        });
      } else if (name === "isLearnerActive") {
        setTempLearner({
          ...tempLearner,
          active: checked,
        });
      } else {
        setTempLearner({
          ...tempLearner,
          [name]: value,
        });

        if (value && learnerValidations[name]) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
      }

      // Check for changes after the update
      setHasChanged(
        Object.keys(tempLearner).some(
          (key) => tempLearner[key] !== initialLearner[key]
          // !(key === name && value === initialLearner[key])
        )
      );
    }
  };

  // Ensure Save button is disabled/enabled when fields change
  useEffect(() => {
    // Initialize the initialLearner only once when the component mounts
    if (Object.keys(initialLearner).length === 0 && tempLearner) {
      setInitialLearner({ ...tempLearner });
    }
  }, [tempLearner, initialLearner]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assume you have an API endpoint to get user data

        populateForm(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [open]);

  const populateForm = (responseData) => {
    setTempLearner(responseData);
  };

  const updateLearner = async (data) => {
    // 1- Validations
    if (tempLearner.name === "") {
      setLearnerValidations({
        ...learnerValidations,
        name: true,
      });
      return;
    }
    if (tempLearner.email === "") {
      setLearnerValidations({
        ...learnerValidations,
        email: true,
      });
      return;
    }

    // 4- Prepare Learner Object
    let learner = {
      email: tempLearner.email,
      uln: tempLearner.uln,
      name: tempLearner.name,
      trainingProviderName: tempLearner.trainingProviderName,
      employerName: tempLearner.employerName,
      apprenticeshipStandard: tempLearner.apprenticeshipStandard,
      active: tempLearner.active,
      imageUrl: tempLearner.imageUrl,
      startDate: tempLearner.startDate,
      endDate: tempLearner.endDate,
    };
    // console.log("initial", learner);

    // Ensure imageUrl is null when updating
    if (profile.imageUrl === null) {
      learner.imageUrl = null; // Explicitly setting imageUrl to null
    }

    // 5- API Call
    try {
      handleToggle(true);
      let obj = {
        ...data,
        ...profile,
        notifyUser: false,
      };

      let formData = new FormData();
      formData.append("file", profile.newImage);
      formData.append("data", JSON.stringify(learner));

      await axios
        .put("learner/update-my-profile", formData, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          obj.imageUrl = response.data.data.imageUrl;
          close();
          handleToggle(false);
          updateMessage(response.data.message);
          getUserData();
          setHasChanged(false);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Set/ Update The Filtered Faculties When 'faculties' State Changes
  useEffect(() => {
    if (allFaculties && allFaculties.length > 0) {
      let coach = [],
        manager = [],
        mentor = [],
        visitor = [],
        trainer = [];

      for (let faculty of allFaculties) {
        if (faculty?.role === "coach") {
          coach.push(faculty);
        } else if (faculty?.role === "manager") {
          manager.push(faculty);
        } else if (faculty?.role === "mentor") {
          mentor.push(faculty);
        } else if (faculty?.role === "visitor") {
          visitor.push(faculty);
        } else if (faculty?.role === "trainer") {
          trainer.push(faculty);
        }
      }
      setFilteredFaculties({
        coach,
        manager,
        mentor,
        visitor,
        trainer,
      });
    }
  }, [allFaculties]);

  const newPictureHandler = async (e) => {
    console.log("eeeeeee");

    e.preventDefault();
    let image = e.target.files[0];

    const imageUrl = URL.createObjectURL(image);

    setProfile((prevProfile) => ({
      ...prevProfile, // Spread the existing profile state
      newImage: image, // Update newImage with the selected image
      imageUrl: imageUrl, // Set the temporary image URL
    }));
    setTempLearner((prevTempLearner) => ({
      ...prevTempLearner, // Spread the existing tempLearner data
      imageUrl: imageUrl, // Set only the imageUrl to null
    }));
    // Set the "has changed" state to trigger UI updates or form validations
    setHasChanged(true);
  };

  const deleteProfilePicture = async () => {
    // Clear the blob URL if it's a Blob
    if (profile.imageUrl && profile.imageUrl.startsWith("blob:")) {
      URL.revokeObjectURL(profile.imageUrl);
    }

    // Then set it to null
    setProfile((prevProfile) => ({
      ...prevProfile,
      imageUrl: null,
      newImage: null,
    }));

    setTempLearner((prevTempLearner) => ({
      ...prevTempLearner,
      imageUrl: null,
      newImage: null,
    }));

    setHasChanged(true);
  };

  const resetProfile = () => {
    setProfile({
      ...profile,
      newImage: null,
      imageUrl: data?.imageUrl, // Reset to original data
    });
    setHasChanged(false); // No changes detected
  };

  useEffect(() => {
    setProfile({
      ...profile,
      imageUrl: data?.imageUrl,
    });
    setHasChanged(false); // Reset change detection
  }, [open]);

  // Cancel button logic
  const handleCancel = () => {
    resetProfile(); // Reset profile changes
    close();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={close}
      PaperProps={{
        sx: { width: matches ? "45%" : "100%" },
      }}
    >
      <Box sx={{ m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Update Profile
          </Typography>
          <Iconify
            icon={"radix-icons:cross-2"}
            sx={{ width: 30, height: 24, cursor: "pointer" }}
            onClick={close}
          />

          {/* <CloseIcon
            style={{ width: "90px", paddingTop: "2px", cursor: "pointer" }}
            // className={classes.icons}
            title="Close"
            onClick={close}
          /> */}
        </Grid>
        <FormGroup sx={{ paddingTop: 3 }}>
          <div className="profileImage">
            <div className="profileImageWrapper">
              <span>
                <Avatar
                  alt={tempLearner && tempLearner.name}
                  src={tempLearner?.imageUrl}
                  sx={{ width: 100, height: 100 }}
                />
              </span>
              <input
                id="selectImage"
                name="imageUrl"
                accept="image/*"
                type="file"
                hidden
                onChange={(e) => newPictureHandler(e)}
              />
              <EditIcon
                className="editIcon"
                title="Edit"
                onClick={() => {
                  document.getElementById("selectImage").click();
                }}
              />

              {tempLearner?.imageUrl && (
                <DeleteIcon
                  className="deleteIcon"
                  title="Delete"
                  onClick={() => deleteProfilePicture()}
                />
              )}
            </div>
          </div>
          <TextField
            label="ULN (Unique Learner Number)"
            variant="outlined"
            name="uln"
            className={classes.field}
            value={tempLearner?.uln}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.uln}
            helperText={learnerValidations.uln && "This can't be empty."}
          />
          <Grid container spacing={3} className={classes.field}>
            <Grid item sm={12} md={12} className="fullWidthInput">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={tempLearner?.name}
                onChange={(e) => updateLearnerChangeHandler(e)}
                error={learnerValidations.name}
                helperText={learnerValidations.name && "This can't be empty."}
              />
            </Grid>
          </Grid>

          <TextField
            label="Work Email"
            variant="outlined"
            className={classes.field}
            type="email"
            name="email"
            value={tempLearner?.email}
            // disabled
            // value={tempLearner?.name}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.email}
            helperText={learnerValidations.email && "This can't be empty."}
          />

          <TextField
            label="Training Provider Name"
            variant="outlined"
            className={classes.field}
            type="email"
            name="trainingProviderName"
            value={tempLearner?.trainingProviderName}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.trainingProviderName}
            helperText={
              learnerValidations.trainingProviderName && "This can't be empty."
            }
          />

          <TextField
            label="Employer Name"
            variant="outlined"
            className={classes.field}
            name="employerName"
            value={tempLearner?.employerName}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.employerName}
            helperText={
              learnerValidations.employerName && "This can't be empty."
            }
          />

          <TextField
            label="Apprenticeship Standard"
            variant="outlined"
            className={classes.field}
            name="apprenticeshipStandard"
            value={tempLearner?.apprenticeshipStandard}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.apprenticeshipStandard}
            helperText={
              learnerValidations.apprenticeshipStandard &&
              "This can't be empty."
            }
          />
        </FormGroup>
        <div className="drawerFooter editLearnerModal__footer">
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              className={classes.button}
              onClick={updateLearner}
              disabled={!hasChanged}
            >
              Save
            </Button>

            <Button
              variant="contained"
              className={classes.buttonn}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

export default UpdateLearner;
